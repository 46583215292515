import React, { useState } from "react";
import {
	TextField,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
	FormLabel,
	Button,
	Box,
	Typography,
	Paper,
	Divider,
	InputAdornment,
	IconButton,
	Link,
	Alert,
} from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import CalculateIcon from "@mui/icons-material/Calculate";
import image1 from "./converter.jpg"; // Adjust the path if needed

function App() {
	const [cgpa, setCgpa] = useState("");
	const [totalCgpa, setTotalCgpa] = useState(4);
	const [percentage, setPercentage] = useState(null);
	const [error, setError] = useState("");

	const handleCgpaChange = (event) => {
		let value = event.target.value;

		// Allow only numbers and a single dot for float values
		if (!/^\d*\.?\d*$/.test(value)) {
			return; // Exit if input is invalid
		}

		// Prevent input of values greater than total CGPA
		if (parseFloat(value) > totalCgpa) {
			setError(`CGPA cannot be greater than the total CGPA of ${totalCgpa}.`);
			return;
		}

		// Limit the input to two decimal places
		if (value.includes(".")) {
			const [integerPart, decimalPart] = value.split(".");
			if (decimalPart.length > 2) {
				value = `${integerPart}.${decimalPart.slice(0, 2)}`; // Truncate to two decimal places
			}
		}

		// Clear error and set CGPA if valid
		setError("");
		setCgpa(value);

		// Recalculate percentage if CGPA is valid
		if (!isNaN(value) && value !== "") {
			const calculatedPercentage = applyFormula(parseFloat(value), totalCgpa);
			setPercentage(calculatedPercentage);
		} else {
			setPercentage(null); // Clear percentage if input is invalid
		}
	};

	const handleTotalCgpaChange = (event) => {
		const newTotalCgpa = parseInt(event.target.value);
		setTotalCgpa(newTotalCgpa);

		// Recalculate percentage if a valid CGPA is already entered
		if (cgpa && !isNaN(cgpa)) {
			if (parseFloat(cgpa) > newTotalCgpa) {
				// Clear CGPA input if it exceeds the new total CGPA
				setCgpa("");
				setPercentage(null);
				setError(`CGPA cannot be greater than ${newTotalCgpa}.`);
			} else {
				setError("");
				const calculatedPercentage = applyFormula(
					parseFloat(cgpa),
					newTotalCgpa
				);
				setPercentage(calculatedPercentage);
			}
		} else {
			setError("");
			setPercentage(null);
		}
	};

	const applyFormula = (cgpa, totalCgpa) => {
		if (totalCgpa === 4) {
			// For CGPA out of 4
			if (cgpa >= 3.63) return ((cgpa - 0.3) / 0.037).toFixed(2);
			if (cgpa >= 3.25) return ((cgpa - 0.29) / 0.037).toFixed(2);
			if (cgpa >= 2.88) return ((cgpa - 0.36) / 0.036).toFixed(2);
			if (cgpa >= 2.5) return ((cgpa - 0.28) / 0.037).toFixed(2);
			if (cgpa >= 1.8) return ((cgpa + 1.65) / 0.069).toFixed(2);
			if (cgpa >= 1) return ((cgpa + 2.16) / 0.079).toFixed(2);
			if (cgpa > 0) return (cgpa / 0.0248).toFixed(2);
		} else if (totalCgpa === 5) {
			// For CGPA out of 5
			if (cgpa >= 4.63) return ((cgpa - 1.3) / 0.037).toFixed(2);
			if (cgpa >= 4.25) return ((cgpa - 1.29) / 0.037).toFixed(2);
			if (cgpa >= 3.88) return ((cgpa - 1.36) / 0.036).toFixed(2);
			if (cgpa >= 3.5) return ((cgpa - 1.28) / 0.037).toFixed(2);
			if (cgpa >= 2.8) return ((cgpa + 0.65) / 0.069).toFixed(2);
			if (cgpa >= 2) return ((cgpa + 1.16) / 0.079).toFixed(2);
			if (cgpa > 0) return ((cgpa - 1) / 0.0248).toFixed(2);
		}
		return 0;
	};

	const handleReset = () => {
		setCgpa("");
		setPercentage(null);
		setError("");
	};

	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			minHeight="100vh"
			sx={{
				backgroundImage: `url(${image1})`, // Set background image
				backgroundSize: "cover", // Cover the entire area
				backgroundRepeat: "no-repeat", // Do not repeat the image
				backgroundPosition: "center", // Center the image
				padding: 2,
				overflow: "hidden", // Prevent scrolling
			}}
		>
			<Paper
				elevation={6}
				sx={{
					padding: 4,
					borderRadius: 3,
					maxWidth: 600,
					textAlign: "center",
					backgroundColor: "#ffffff",
					opacity: 0.9, // Slight transparency for better text visibility
				}}
			>
				<Box mb={3}>
					<SchoolIcon sx={{ fontSize: 50, color: "#4caf50" }} />
				</Box>

				<Typography
					variant="h4"
					gutterBottom
					sx={{ color: "#2e7d32", fontWeight: "bold" }}
				>
					Online CGPA to Percentage Converter as Per HEC Formula in Pakistan
					2024
				</Typography>

				<Divider sx={{ my: 3 }} />

				<Typography
					variant="body1"
					sx={{ color: "#455a64", mb: 4, fontStyle: "italic" }}
				>
					Easily convert your CGPA to percentage using the official formula
					provided by the Higher Education Commission (HEC) Pakistan. Ensure
					your academic records are accurately represented when applying for
					further education or jobs. We have provided options for both 4.0 and
					5.0 CGPA scales. Select the relevant scale and enter your CGPA to
					convert.
				</Typography>

				<FormControl component="fieldset" sx={{ marginBottom: 3 }}>
					<FormLabel
						component="legend"
						sx={{ color: "#1976d2", fontWeight: "bold" }}
					>
						Select Total CGPA
					</FormLabel>
					<RadioGroup
						row
						aria-label="total-cgpa"
						name="total-cgpa"
						value={totalCgpa}
						onChange={handleTotalCgpaChange}
						sx={{ justifyContent: "center" }}
					>
						<FormControlLabel
							value={4}
							control={<Radio color="primary" />}
							label="4"
						/>
						<FormControlLabel
							value={5}
							control={<Radio color="primary" />}
							label="5"
						/>
					</RadioGroup>
				</FormControl>

				{error && (
					<Alert severity="error" sx={{ marginBottom: 3 }}>
						{error}
					</Alert>
				)}

				<TextField
					label="Enter CGPA"
					variant="outlined"
					value={cgpa}
					onChange={handleCgpaChange}
					fullWidth
					error={!!error}
					sx={{
						marginBottom: 4,
						backgroundColor: "#e8f0fe",
						borderRadius: 1,
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton edge="end">
									<CalculateIcon sx={{ color: "#1976d2" }} />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>

				<Button
					variant="outlined"
					color="secondary"
					onClick={handleReset}
					size="small" // Set button size to small
					sx={{
						marginTop: 2,
						padding: 1,
						fontSize: "0.875rem", // Adjust font size
						fontWeight: "bold",
						borderRadius: "20px", // Rounded button for better appearance
						border: "2px solid #1976d2", // Add border for styling
						color: "#1976d2", // Text color
						"&:hover": {
							backgroundColor: "#e3f2fd", // Light blue on hover
						},
					}}
				>
					Reset
				</Button>

				{percentage !== null && (
					<Paper
						elevation={3}
						sx={{ padding: 3, marginTop: 4, backgroundColor: "#f8f9fa" }}
					>
						<Typography
							variant="h5"
							sx={{ color: "#d32f2f", fontWeight: "bold", mb: 1 }}
						>
							Calculated Percentage: {percentage}%
						</Typography>
						<Typography
							variant="h6"
							sx={{ color: "#455a64", fontWeight: "bold" }}
						>
							CGPA Scored: {cgpa} / {totalCgpa}
						</Typography>
					</Paper>
				)}

				<Divider sx={{ my: 4 }} />
				<Typography variant="body2" sx={{ color: "#757575", marginTop: 2 }}>
					Developed by{" "}
					<Link
						href="https://www.linkedin.com/in/usama-bhatti-4061/"
						target="_blank"
						rel="noopener"
						sx={{
							color: "#1976d2",
							fontWeight: "bold",
							textDecoration: "none",
							"&:hover": { textDecoration: "underline" },
						}}
					>
						Usama Bhatti
					</Link>
				</Typography>
			</Paper>
		</Box>
	);
}

export default App;
